<template>
    <div class="zc_dialog_box">
        <el-dialog title="套餐充值" :visible.sync="setMealShow" width="824px" :close-on-click-modal="false">
            <div class="info_main">
                <div class="form">
                    <div class="form-item mr30">
                        <div class="form-label" style="padding-left: -10px;"><span>* </span>套餐合同金额</div>
                        <div class="form-input">
                            <el-input-number data-unit="元" :min="0" suffix-icon="el-icon-date" v-model="topup_money" controls-position="right"></el-input-number>
                        </div>
                    </div>
                    <div class="form-item mr20">
                        <div class="form-label">客户享有折扣</div>
                        <div class="form-input">
                            <el-input-number data-unit="折" :min="0" :max="100" suffix-icon="el-icon-date" v-model="discount" controls-position="right"></el-input-number>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-tit">
                        充值套餐分配<span>(不填写默认不做充值操作)</span>
                    </div>
                    <div class="form pb40" v-for="(item,index) in setMealList" :key="index">
                        <div class="form-item mr30">
                            <div class="form-label"><span>* </span>套餐类型</div>
                            <div class="form-input">
                                <el-select v-model="item.type" @visible-change="visibleChange($event,item)" @change="change" placeholder="请选择套餐类型">
                                    <el-option v-for="ele in options" :key="ele.value" :label="ele.label" :value="ele.value" :disabled="ele.disabled">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="form-item mr20">
                            <div class="form-label" v-if="item.type==6"><span>* </span>数字人训练次数</div>
                            <div class="form-label" v-if="item.type==15"><span>* </span>钻石充值</div>
                            <div class="form-label" v-if="item.type==4"><span>* </span>时长充值</div>
                            <div class="form-input">
                                <el-input-number :min="1" :data-unit="item.type==6?'次':item.type==15?'钻':'分'" suffix-icon="el-icon-date" v-model="item.num" controls-position="right"></el-input-number>
                            </div>
                        </div>
                        <div class="del" v-if="index!=0" @click="delSetMeal(index,item.type)">
                            <img src="@/assets/images/person/customer/del.png" alt="">
                        </div>
                    </div>
                    <div class="add-btn" v-if="setMealList.length<3">
                        <span @click="addSetMeal"> + 新增充值类型</span>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="setMealShow = false">取 消</el-button>
                <el-button size="small" type="primary" :loading="btnLoading" @click="confirm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        topup: Array,
    },
    data() {
        return {
            setMealShow: false,
            btnLoading: false,
            topup_money: 0,
            discount: 0,
            setMealList: [],
            options: [],
            focusType: '',
            id: '',
        };
    },
    created() {

    },
    methods: {
        confirm() {
            let params = {
                id: this.id,
                topup_money: this.topup_money,
                discount: this.discount,
            }
            let arr = []
            this.setMealList.forEach(ele => {
                if (ele.num == undefined) {
                    this.$errMsg('请输入套餐数量')
                    return
                } else {
                    arr.push({
                        id: ele.type,
                        value: ele.num
                    })
                }
            })
            params.topup_arr = arr
            if (params.topup_money == undefined) {
                this.$errMsg('请输入套餐金额')
                return
            }
            if (params.topup_money == 0) {
                this.$errMsg('套餐金额不能为0')
                return
            }
            this.btnLoading = true
            this.$personApi.customerTopup(params).then(res => {
                this.btnLoading = false
                if (res.code == 1000) {
                    this.setMealShow = false;
                    this.$succMsg(res.message)
                    this.$emit('getList', '')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        open(id) {
            this.id = id
            // 打开弹窗先清除数据
            this.btnLoading = false;
            this.focusType = '';
            this.setMealShow = true
            this.price = 0;
            this.discount = 0;
            this.setMealList = []

            this.topup.forEach((ele, index) => {
                if (index == 0) {
                    ele.disabled = true
                } else {
                    ele.disabled = false
                }
            })
            this.options = JSON.parse(JSON.stringify(this.topup))
            this.setMealList.push({
                type: this.topup[0].value,
                num: "",
                label: this.topup[0].label
            })
        },
        // 添加套餐
        addSetMeal() {
            let arr = this.options.filter(item => item.disabled === false)
            this.disabledTrue(arr[0].value)
            this.setMealList.push({
                type: arr[0].value,
                num: '',
                label: arr[0].label
            })
        },
        // 删除套餐
        delSetMeal(index, val) {
            this.setMealList.splice(index, 1)
            // 解除禁用
            this.disabledFalse(val)
        },
        change(val) {
            this.disabledTrue(val)
            this.disabledFalse(this.focusType)
            this.focusType = ''
        },
        // 下拉时候 获取切换前的type  然后解除禁用
        visibleChange(val, item) {
            if (val) {
                this.focusType = item.type
            }
        },
        // 禁用
        disabledTrue(val) {
            this.options.forEach(ele => {
                if (ele.value == val) {
                    ele.disabled = true
                }
            })
        },
        // 解除禁用
        disabledFalse(val) {
            this.options.forEach(ele => {
                if (ele.value == val) {
                    ele.disabled = false
                }
            })
        },
    },
};
</script>

<style scoped lang="scss">
.info_main {
    padding: 10px 30px;
    width: 100%;
    .card {
        width: 724px;
        min-height: 250px;
        background: #f2f7ff;
        border-radius: 6px;
        margin-top: 20px;
        padding: 20px 0 0 0;
        .card-tit {
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            padding: 0 0 20px 20px;
            span {
                color: #999999;
                font-size: 12px;
                padding-left: 14px;
            }
        }
        .add-btn {
            font-size: 15px;
            color: #2e4bf2;
            line-height: 20px;
            padding-left: 40px;
            margin-top: -20px;
            span {
                cursor: pointer;
            }
        }
    }
    .pb40 {
        padding-bottom: 40px;
    }
    .form {
        display: flex;
        align-items: center;

        .form-item {
            display: flex;
            align-items: center;
            .form-label {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
                width: 110px;
                margin-right: 20px;
                text-align: right;
                span {
                    color: #e00c25;
                }
            }
            .form-input {
                width: 180px;
            }
        }
        .del {
            width: 36px;
            height: 36px;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.mr20 {
    margin-right: 20px;
}
.mr30 {
    margin-right: 30px;
}
</style>
<style>
.el-input-number[data-unit] {
    --el-input-number-unit-offset-x: calc(50px + 4px);
}
.el-input-number--small[data-unit] {
    --el-input-number-unit-offset-x: calc(32px + 4px);
}
.el-input-number[data-unit]::after {
    content: attr(data-unit);
    position: absolute;
    top: 0;
    right: var(--el-input-number-unit-offset-x);
    color: #999999;
    height: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.el-input-number[data-unit] .el-input__inner {
    padding-right: calc(1em + var(--el-input-number-unit-offset-x) + 4px);
}
</style>
