<!-- @Author: Yu_Bo -->
<template>
    <div class='vueMain'>
        <div class="model-search">
            <div class="model-search-l">
                <div class="search-nav" :class="{active:navCurrent==item.value}" v-for="item in navList" :key="item.value" @click="changeNav(item.value)">
                    <span>{{item.name}}</span>
                </div>
            </div>
            <div class="model-search-r" v-if="navCurrent=='2'">
                <div class="search-box">
                    <el-input size="small" @keyup.enter.native="changeMobile" clearable v-model="mobile" prefix-icon="el-icon-search" placeholder="请输入客户手机号"></el-input>
                </div>
                <div class="btn">
                    <div class="btn-item" :class="{active:item.value==dateCurrent}" @click="changeData(item.value)" v-for="(item,index) in dateList" :key="index">
                        {{item.label}}
                    </div>
                </div>
                <div class="search-time">
                    <el-date-picker v-model="time" size="small" :disabled="dateCurrent!='4'" type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" value-format="yyyy-MM-dd" @change="changeTime" clearable>
                    </el-date-picker>
                </div>
            </div>
        </div>
        <my-customer :source_list="source_list" :topup="topup" @changeCurrent="changeCurrent" v-if="navCurrent=='1'"></my-customer>
        <customer-order ref="customerOrder" :id="id" :pay_source="pay_source" :product_category="product_category" :order_source="order_source" v-if="navCurrent=='2'"></customer-order>
    </div>
</template>

<script>
import MyCustomer from '../components/my_customer/index.vue'     //我的客户
import CustomerOrder from '../components/customer_order/index.vue'    //客户订单
export default {
    components: {
        MyCustomer,
        CustomerOrder,
    },
    props: {},
    data() {
        return {
            mobile: "",
            navList: [
                {
                    value: "1",
                    name: "我的客户",
                },
                {
                    value: "2",
                    name: "客户订单",
                },
            ],
            navCurrent: '1',
            dateList: [
                {
                    label: '今日',
                    value: '1'
                },
                {
                    label: '本周',
                    value: '2'
                },
                {
                    label: '本月',
                    value: '3'
                },
                {
                    label: '自定义',
                    value: '4'
                },
            ],
            dateCurrent: "1",
            source_list: [],   //客户类型
            pay_source: [],   //充值来源
            product_category: [],  //商品分类
            order_source: [],  //订单来源
            time: [],
            id: '',
            topup: []
        }
    },
    created() {
        this.getParams()
        this.getRechargeParams()
        this.getConsumeParams()
        this.getCustomerTopupSelect()
    },
    methods: {
        // 获取套餐

        getCustomerTopupSelect() {
            this.$personApi.customerTopupSelect().then(res => {
                if (res.code == 1000) {
                    let arr = res.result.topup
                    arr.forEach(ele => {
                        this.topup.push({
                            value: ele.value,
                            label: ele.label.name,
                        })
                    })
                }
            })
        },
        // 获取商品类别 订单来源
        getConsumeParams() {
            this.$personApi.orderConsumeParams().then(res => {
                if (res.code == 1000) {
                    this.product_category = res.result.product_category
                    this.order_source = res.result.order_source
                }
            })
        },
        // 获取充值来源
        getRechargeParams() {
            this.$personApi.orderRechargeParams().then(res => {
                if (res.code == 1000) {
                    this.pay_source = res.result.order_source
                }
            })
        },
        // 获取客户类型
        getParams() {
            this.$personApi.customerParams().then(res => {
                if (res.code == 1000) {
                    this.source_list = res.result.type
                }
            })
        },
        changeCurrent(val) {
            this.navCurrent = '2'
            this.id = val
        },
        changeNav(val) {
            this.id = ''
            this.navCurrent = val
            if (val == '1') {
                this.mobile = '';
                this.dateCurrent = '1';
                this.time = []
            }
        },
        changeMobile() {
            this.$refs.customerOrder.changeMobile(this.mobile)
        },
        changeData(val) {
            this.dateCurrent = val
            if (val != 4) {
                this.$refs.customerOrder.changeData(val)
            } else {
                if (this.time.length != 0) {
                    let str = this.time[0] + '|' + this.time[1]
                    this.$refs.customerOrder.changeTime(str)
                }
            }
        },
        // 修改时间
        changeTime(val) {
            if (val) {
                let str = this.time[0] + '|' + this.time[1]
                this.$refs.customerOrder.changeTime(str)
            } else {
                let str = ''
                this.$refs.customerOrder.changeTime(str)
            }
        },
    },
}
</script>

<style lang='scss' scoped>
.vueMain {
    .model-search {
        width: 100%;
        background: rgba($color: #ffffff, $alpha: 0.4);
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        padding: 15px 20px 15px 20px;
        .model-search-l {
            display: flex;
            flex-wrap: wrap;
            width: 250px;
            .search-nav {
                width: 60px;
                color: $bColor3;
                line-height: 32px;
                font-size: 14px;
                margin-right: 40px;
                cursor: pointer;
                position: relative;
            }
            .search-nav.active {
                color: $blueColor1;
            }
            .search-nav.active::after {
                content: "";
                width: 24px;
                height: 2px;
                background: $blueColor1;
                border-radius: 1px;
                position: absolute;
                margin-left: -14px;
                bottom: 0;
                left: 50%;
            }
        }
        .model-search-r {
            display: flex;
            justify-content: flex-end;
            .search-box {
                width: 186px;
                margin-right: 20px;
            }
            .search-time {
                margin-left: 20px;
            }
            .btn {
                display: flex;
                height: 32px;
                cursor: pointer;
                .btn-item {
                    width: 58px;
                    line-height: 30px;
                    font-size: 14px;
                    color: #666666;
                    border: 1px solid #c1c1c1;
                    text-align: center;
                }
                .btn-item.active {
                    color: $blueColor1;
                    border: 1px solid $blueColor1;
                }
                .btn-item:last-child {
                    border-radius: 0px 2px 2px 0px;
                }
                .btn-item:first-child {
                    border-radius: 2px 0px 0px 2px;
                }
            }
        }
    }
}
</style>