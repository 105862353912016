<template>
    <div class="zc_dialog_box">
        <el-dialog :title="titleText" :visible.sync="dialogAdd" width="622px" @close="close" :close-on-click-modal="false">
            <div class="add_main" v-loading="loading">
                <div class="main_text center" v-if="titleText=='新增客户'">
                    <div class="left"><span>*</span>身份</div>
                    <div class="right">
                        <el-radio v-model="form.type" label="1">个人</el-radio>
                        <el-radio v-model="form.type" label="2">企业</el-radio>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>客户名称</div>
                    <div class="right">
                        <el-input v-model="form.nickname" maxlength="10" placeholder="请输入客户名称"></el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>联系人</div>
                    <div class="right">
                        <el-input v-model="form.contact" maxlength="10" placeholder="请输入联系人"></el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>登录手机号</div>
                    <div class="right">
                        <el-input v-model="form.mobile" placeholder="请输入登录手机号"></el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span v-if="form.type=='2'">*</span>身份证照片</div>
                    <div class="id-card">
                        <upload-img width="200px" height="125px" bg_img="bind3.png" label="上传人像面" :imageUrl="form.idcard_front" @uploadSuccess="uploadSuccess1"></upload-img>
                        <upload-img width="200px" height="125px" bg_img="bind4.png" label="上传国徽面" :imageUrl="form.idcard_reverse" @uploadSuccess="uploadSuccess2"></upload-img>
                    </div>
                </div>
                <div class="main_text" v-if="form.type=='2'">
                    <div class="left"><span>*</span>营业执照</div>
                    <div class="id-card">
                        <upload-img width="200px" height="135px" bg_img="bind5.png" label="上传营业执照" :imageUrl="form.business_license" @uploadSuccess="uploadSuccess3"></upload-img>
                    </div>
                </div>
                <div class="main_text" v-if="form.type=='2'" style="margin-bottom: 0;">
                    <div class="left">公司介绍</div>
                    <div class="right">
                        <el-input type="textarea" :rows="3" placeholder="请输入公司介绍" v-model="form.introduce" maxlength="150" show-word-limit>
                        </el-input>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogAdd = false">取 消</el-button>
                <el-button size="small" type="primary" :loading="btnLoading" @click="confirm">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="查看资质" :visible.sync="dialogInfo" width="610px" @close="close" :close-on-click-modal="false">
            <div class="info_main" v-loading="loading">
                <div class="info_title" style="padding-top: 0;" v-if="info.type==2">
                    公司介绍
                </div>
                <div class="info_desc" v-if="info.type==2">
                    {{info.introduce || '--'}}
                </div>
                <div class="info_title">
                    身份证照片
                </div>
                <div class="picture">
                    <img class="idcard" :src="info.idcard_front" alt="">
                    <img class="idcard" :src="info.idcard_reverse" alt="">
                </div>
                <div class="info_title" v-if="info.type==2">
                    营业执照
                </div>
                <div class="picture" v-if="info.type==2">
                    <img class="idcard" :src="info.business_license" alt="">
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import UploadImg from '@/components/upload/upload_img.vue'
export default {
    components: { UploadImg },
    data() {
        return {
            dialogAdd: false,
            dialogInfo: false,
            btnLoading: false,
            loading: false,
            form: {
                type: '1',
                nickname: '',
                contact: '',
                mobile: '',
                idcard_front: '',
                idcard_reverse: '',
                business_license: '',
                introduce: '',
            },
            info: {},
            titleText: '新增客户',
            id: '',
        };
    },
    created() {

    },
    mounted() {

    },
    methods: {
        confirm() {
            if (this.form.nickname == "") {
                this.$errMsg("请输入客户名称")
                return
            }
            if (this.form.contact == "") {
                this.$errMsg("请输入联系人")
                return
            }
            if (this.form.mobile == "") {
                this.$errMsg("请输入手机号")
                return
            }
            if (!/^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[7]))\d{8}$/.test(this.form.mobile)) {
                this.$errMsg("请输入正确的手机号")
                return
            }

            if (this.titleText == '新增客户') {
                // 新增客户
                if (this.form.type == '1') {
                    this.addIndividual()
                } else {
                    this.addEnterprise()
                }
            } else {
                // 编辑客户
                if (this.form.type == '1') {
                    this.editIndividual()
                } else {
                    this.editEnterprise()
                }
            }
        },
        // 编辑个人
        editIndividual() {
            var params = {
                id: this.id,
                nickname: this.form.nickname,
                contact: this.form.contact,
                mobile: this.form.mobile,
                idcard_front: this.form.idcard_front,
                idcard_reverse: this.form.idcard_reverse,
            }
            this.btnLoading = true
            this.$personApi.customerUpdate(params).then(res => {
                this.btnLoading = false
                if (res.code == 1000) {
                    this.dialogAdd = false;
                    this.$succMsg(res.message)
                    this.$emit('getList', '')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 编辑企业
        editEnterprise() {
            if (this.form.idcard_front == "") {
                this.$errMsg("请上传身份证正面")
                return
            }
            if (this.form.idcard_reverse == "") {
                this.$errMsg("请上传身份证背面")
                return
            }
            if (this.form.business_license == "") {
                this.$errMsg("请上传营业执照")
                return
            }
            var params = {
                id: this.id,
                nickname: this.form.nickname,
                contact: this.form.contact,
                mobile: this.form.mobile,
                idcard_front: this.form.idcard_front,
                idcard_reverse: this.form.idcard_reverse,
                business_license: this.form.business_license,
                introduce: this.form.introduce,
            }
            this.btnLoading = true
            this.$personApi.customerUpdate(params).then(res => {
                this.btnLoading = false
                if (res.code == 1000) {
                    this.dialogAdd = false;
                    this.$succMsg(res.message)
                    this.$emit('getList', '')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 查看资质
        customerRead(id, type) {
            var params = {
                id: id
            }
            this.loading = true
            this.$personApi.customerRead(params).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.info = res.result
                    // 编辑
                    if (type == 'edit') {
                        this.form.type = res.result.type + ''
                        this.form.nickname = res.result.name
                        this.form.contact = res.result.contact
                        this.form.idcard_front = res.result.idcard_front
                        this.form.idcard_reverse = res.result.idcard_reverse
                        this.form.business_license = res.result.business_license
                        this.form.introduce = res.result.introduce
                    }
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 添加个人
        addIndividual() {
            var params = {
                type: '1',
                nickname: this.form.nickname,
                contact: this.form.contact,
                mobile: this.form.mobile,
                idcard_front: this.form.idcard_front,
                idcard_reverse: this.form.idcard_reverse,
            }
            this.btnLoading = true
            this.$personApi.customerAdd(params).then(res => {
                this.btnLoading = false
                if (res.code == 1000) {
                    this.dialogAdd = false;
                    this.$succMsg(res.message)
                    this.$emit('getList', '')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 添加企业
        addEnterprise() {
            if (this.form.idcard_front == "") {
                this.$errMsg("请上传身份证正面")
                return
            }
            if (this.form.idcard_reverse == "") {
                this.$errMsg("请上传身份证背面")
                return
            }
            if (this.form.business_license == "") {
                this.$errMsg("请上传营业执照")
                return
            }
            var params = {
                type: '2',
                nickname: this.form.nickname,
                contact: this.form.contact,
                mobile: this.form.mobile,
                idcard_front: this.form.idcard_front,
                idcard_reverse: this.form.idcard_reverse,
                business_license: this.form.business_license,
                introduce: this.form.introduce,
            }
            this.btnLoading = true
            this.$personApi.customerAdd(params).then(res => {
                this.btnLoading = false
                if (res.code == 1000) {
                    this.dialogAdd = false;
                    this.$succMsg(res.message)
                    this.$emit('getList', '')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        openInfo(id) {
            this.dialogInfo = true
            this.customerRead(id)
        },
        openAdd() {
            this.titleText = '新增客户'
            this.dialogAdd = true
        },
        openEdit(id, mobile) {
            this.titleText = '编辑客户'
            this.dialogAdd = true
            this.form.mobile = mobile;
            this.id = id;
            this.customerRead(id, 'edit')
        },
        // 上传身份证正面
        uploadSuccess1(url) {
            this.form.idcard_front = url
        },
        // 上传身份证反面
        uploadSuccess2(url) {
            this.form.idcard_reverse = url
        },
        // 上传营业执照
        uploadSuccess3(url) {
            this.form.business_license = url
        },
        close() {
            this.form = {
                type: '1',
                nickname: '',
                contact: '',
                mobile: '',
                idcard_front: '',
                idcard_reverse: '',
                business_license: '',
                introduce: '',
            }
            this.info = {}
        },
    },
};
</script>

<style scoped lang="scss">
.add_main {
    width: 100%;

    .center {
        align-items: center;
    }
    .main_text {
        width: 100%;
        margin-bottom: 20px;
        display: flex;

        .left {
            width: 100px;
            text-align: right;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 40px;
            margin-right: 20px;

            span {
                padding-right: 5px;
                color: #e00c25;
            }
        }

        .right {
            width: 360px;

            .el-input {
                width: 100%;
            }

            .el-date-editor {
                width: 100%;
            }
        }
        .id-card {
            width: 420px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .id-card-item {
                .id-card-img {
                    position: relative;
                    width: 200px;
                    height: 125px;
                    .img {
                        width: 100%;
                        height: 100%;
                    }
                    .upload {
                        position: absolute;
                        width: 43px;
                        height: 43px;
                        top: 41px;
                        left: 79px;
                        z-index: 10;
                        cursor: pointer;
                    }
                }
                .id-card-tit {
                    font-size: 14px;
                    color: #999999;
                    line-height: 20px;
                    text-align: center;
                    margin-top: 13px;
                }
            }
        }
    }
}

.info_main {
    padding: 0 30px 50px;
    .info_title {
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        padding: 30px 0 20px 0;
    }
    .info_desc {
        width: 510px;
        background: #f8f8f8;
        border-radius: 4px;
        padding: 12px 16px;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
    }
    .picture {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .idcard {
            width: 240px;
        }
    }
}

::v-deep.zc_dialog_box .el-dialog {
    margin-top: 7vh !important;
}
</style>
