<template>
    <div>
        <div class="con_bottom sec_wrap">
            <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="充值记录" name="first"></el-tab-pane>
                <el-tab-pane label="钻石消耗" name="second"></el-tab-pane>
                <el-tab-pane label="兑换码兑换" name="third"></el-tab-pane>
            </el-tabs>
            <div class="content1 p20" v-if="activeName == 'first'">
                <el-form ref="params" :model="params" :inline="true" label-position="left" size='small'>
                    <el-form-item label="" props="source">
                        <el-input size="small" v-model="params.nickname" @keyup.enter.native="search1" clearable prefix-icon="el-icon-search" placeholder="请输入客户名称"></el-input>
                    </el-form-item>
                    <el-form-item label="" props="source">
                        <el-input size="small" v-model="params.agency_mobile" @keyup.enter.native="search1" clearable prefix-icon="el-icon-search" placeholder="请输入所属代理商手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="" props="source">
                        <el-input size="small" v-model="params.mobile" @keyup.enter.native="search1" clearable prefix-icon="el-icon-search" placeholder="请输入客户手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="充值来源" props="source">
                        <el-select v-model="params.pay_source" clearable placeholder="请选择充值来源" @change="search1">
                            <el-option v-for="item in pay_source" :key="item.value" :label="item.label" :value="item.value" :clearable="true">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="交易时间">
                        <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" value-format="yyyy-MM-dd" @change="search1" :clearable="true">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <div class="table_wrap">
                    <el-table key="first" :data="list1" v-loading="loading1" style="width: 100%" header-cell-class-name="table-header" cell-class-name="cell-class">
                        <template slot="empty">
                            <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                        </template>
                        <el-table-column prop="order_num" label="订单号" width="210">
                        </el-table-column>
                        <el-table-column prop="order_source_text" label="充值来源" width="140">
                        </el-table-column>
                        <el-table-column prop="corp.name" label="企业名称">
                        </el-table-column>
                        <el-table-column prop="member.mobile" label="企业手机号" width="120">
                        </el-table-column>
                        <el-table-column label="所属代理商">
                            <template slot-scope="scope">
                                <p v-if="scope.row.member">
                                    <span v-if="scope.row.member.memberParentAgency">{{scope.row.member.memberParentAgency.nickname}}</span>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="折扣">
                            <template slot-scope="scope">
                                <span>{{scope.row.discount}}折</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" label="充值额度" width="200">
                            <template slot-scope="scope">
                                <div class="details_text" v-if="scope.row.details && scope.row.details.length">
                                    <div class="text" v-for="(item,index) in scope.row.details" :key="index">
                                        {{item.name}}-<span>{{item.profie}}{{item.unit}}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="total_amount" label="合同金额(元)">
                        </el-table-column>
                        <el-table-column label="操作账户">
                            <template slot-scope="scope">
                                <span>{{scope.row.operator_name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="pay_time" label="交易时间" width="160">
                        </el-table-column>
                    </el-table>
                    <div class="page_box">
                        <paging-page :total='total1' align='right' @sizeChange='sizeChangeBtn1' @currentChange='currentChangeBtn1'></paging-page>
                    </div>
                </div>
            </div>
            <div class="content2 p20" v-if="activeName == 'second'">
                <el-form ref="params" :model="params" :inline="true" label-position="left" size='small'>
                    <el-form-item label="" props="source">
                        <el-input size="small" @keyup.enter.native="search2" clearable v-model="params.nickname" prefix-icon="el-icon-search" placeholder="请输入客户名称"></el-input>
                    </el-form-item>
                    <el-form-item label="" props="source">
                        <el-input size="small" @keyup.enter.native="search2" clearable v-model="params.mobile" prefix-icon="el-icon-search" placeholder="请输入客户手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="商品类别" props="source">
                        <el-select v-model="params.category" clearable placeholder="请选择商品类别" @change="search2">
                            <el-option v-for="item in product_category" :key="item.value" :label="item.label" :value="item.value" :clearable="true">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="订单来源" props="source">
                        <el-select v-model="params.order_source" clearable placeholder="请选择订单来源" @change="search2">
                            <el-option v-for="item in order_source" :key="item.value" :label="item.label" :value="item.value" :clearable="true">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="交易时间">
                        <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" value-format="yyyy-MM-dd" @change="search2" :clearable="true">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <div class="table_wrap">
                    <el-table key="second" :data="list2" v-loading="loading2" style="width: 100%" header-cell-class-name="table-header" cell-class-name="cell-class">
                        <template slot="empty">
                            <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                        </template>
                        <el-table-column prop="order_num" label="订单号" width="250">
                        </el-table-column>
                        <el-table-column prop="order_source_text" label="订单来源">
                        </el-table-column>
                        <el-table-column prop="corp.name" label="企业名称">
                        </el-table-column>
                        <el-table-column prop="member.mobile" label="企业手机号">
                        </el-table-column>
                        <el-table-column prop="product_category_text" label="商品类别">
                        </el-table-column>
                        <el-table-column prop="product_name" label="商品名称">
                        </el-table-column>
                        <el-table-column prop="total_amount" label="消耗额度">
                        </el-table-column>
                        <el-table-column prop="pay_time" label="交易时间" width="160">
                        </el-table-column>
                    </el-table>
                    <div class="page_box">
                        <paging-page :total='total2' align='right' @sizeChange='sizeChangeBtn2' @currentChange='currentChangeBtn2'></paging-page>
                    </div>
                </div>
            </div>
            <div class="content3 p20" v-if="activeName == 'third'">
                <el-form ref="params" :model="params" :inline="true" label-position="left" size='small'>
                    <el-form-item label="" props="source">
                        <el-input size="small" @keyup.enter.native="search3" clearable v-model="params.nickname" prefix-icon="el-icon-search" placeholder="请输入客户名称"></el-input>
                    </el-form-item>
                    <el-form-item label="" props="source">
                        <el-input size="small" @keyup.enter.native="search3" clearable v-model="params.mobile" prefix-icon="el-icon-search" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                </el-form>
                <div class="table_wrap">
                    <el-table key="third" :data="list3" v-loading="loading3" style="width: 100%" header-cell-class-name="table-header" cell-class-name="cell-class">
                        <template slot="empty">
                            <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                        </template>
                        <el-table-column prop="order_num" label="订单号" width="250">
                        </el-table-column>
                        <el-table-column prop="order_source_text" label="订单来源">
                        </el-table-column>
                        <el-table-column prop="corp.name" label="企业名称">
                        </el-table-column>
                        <el-table-column prop="member.mobile" label="企业手机号">
                        </el-table-column>
                        <el-table-column prop="product_category_text" label="商品类别">
                        </el-table-column>
                        <el-table-column prop="product_name" label="商品名称">
                        </el-table-column>
                        <el-table-column prop="pay_time" label="交易时间" width="160">
                        </el-table-column>
                    </el-table>
                    <div class="page_box">
                        <paging-page :total='total3' align='right' @sizeChange='sizeChangeBtn3' @currentChange='currentChangeBtn3'></paging-page>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
export default {
    props: {
        id: String,
        pay_source: Array,   //充值来源
        product_category: Array,  //商品分类
        order_source: Array,  //订单来源
    },
    components: { EmptyData, PagingPage },
    data() {
        return {
            activeName: 'first',
            params: {
                start_date: '',
                end_date: '',
                pay_source: '',   //充值来源   后台需要字段也是order_source   和订单来源重复
                nickname: '',    //客户名称
                mobile: '',    //客户手机号
                agency_mobile: '',   //代理号码
                category: '', //商品类别
                order_source: '',  //订单来源
            },
            date: [],
            page1: 1,
            page2: 1,
            page3: 1,
            limit1: 10,
            limit2: 10,
            limit3: 10,
            total1: 0,
            total2: 0,
            total3: 0,
            loading1: false,
            loading2: false,
            loading3: false,
            list1: [],
            list2: [],
            list3: [],
        };
    },
    created() {
        this.getList1()
    },
    methods: {
        handleClick(tab) {
            this.params = {
                start_date: '',
                end_date: '',
                pay_source: '',
                nickname: '',
                mobile: '',
                agency_mobile: '',
                category: '',
                order_source: '',
            }
            this.date = []
            if (tab.name == 'first') {
                this.page1 = 1;
                this.getList1()
            } else if (tab.name == 'second') {
                this.page2 = 1;
                this.getList2()
            } else {
                this.page3 = 1;
                this.getList3()
            }
        },
        search1() {
            this.page1 = 1
            if (this.date == null) {
                this.params.start_date = ''
                this.params.end_date = ''
            } else {
                this.params.start_date = this.date[0]
                this.params.end_date = this.date[1]
            }
            this.getList1()
        },
        search2() {
            this.page2 = 1
            if (this.date == null) {
                this.params.start_date = ''
                this.params.end_date = ''
            } else {
                this.params.start_date = this.date[0]
                this.params.end_date = this.date[1]
            }
            this.getList2()
        },
        search3() {
            this.page3 = 1
            this.getList3()
        },
        // 充值记录
        getList1() {
            let params = {
                member_id: this.id,
                page: this.page1,
                limit: this.limit1,
                is_agency: 1,
                start_date: this.params.start_date,
                end_date: this.params.end_date,
                order_source: this.params.pay_source,
                nickname: this.params.nickname,
                mobile: this.params.mobile,
                agency_mobile: this.params.agency_mobile,
            }
            this.loading1 = true;
            this.$personApi.getRecharge(params).then(res => {
                this.loading1 = false;
                if (res.code == 1000) {
                    this.list1 = res.result.data
                    this.total1 = res.result.total
                }
            })
        },
        getList2() {
            let params = {
                member_id: this.id,
                page: this.page2,
                limit: this.limit2,
                is_agency: 1,
                pay_type: 3,
                start_date: this.params.start_date,
                end_date: this.params.end_date,
                order_source: this.params.order_source,
                category: this.params.category,
                nickname: this.params.nickname,
                mobile: this.params.mobile,
            }
            this.loading2 = true;
            this.$personApi.getConsume(params).then(res => {
                this.loading2 = false;
                if (res.code == 1000) {
                    this.list2 = res.result.data
                    this.total2 = res.result.total
                }
            })
        },
        getList3() {
            let params = {
                member_id: this.id,
                page: this.page3,
                limit: this.limit3,
                is_agency: 1,
                pay_type: 4,
                nickname: this.params.nickname,
                mobile: this.params.mobile,
            }
            this.loading3 = true;
            this.$personApi.getConsume(params).then(res => {
                this.loading3 = false;
                if (res.code == 1000) {
                    this.list3 = res.result.data
                    this.total3 = res.result.total
                }
            })
        },
        sizeChangeBtn1(val) {
            this.limit1 = val
            this.page1 = 1
            this.getList1()
        },
        currentChangeBtn1(val) {
            this.page1 = val;
            this.getList1()
        },
        sizeChangeBtn2(val) {
            this.limit2 = val
            this.page2 = 1
            this.getList2()
        },
        currentChangeBtn2(val) {
            this.page2 = val;
            this.getList2()
        },
        sizeChangeBtn3(val) {
            this.limit3 = val
            this.page3 = 1
            this.getList3()
        },
        currentChangeBtn3(val) {
            this.page3 = val;
            this.getList3()
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../index.scss";
.con_bottom {
    margin-top: 20px;
}
.details_text {
    color: #666;
    span {
        color: #ec497c;
    }
}
</style>
